.footer {
  color: #000;
  a {
    color: #000;
  }
  &__container {
    padding: 27px 0 30px 0;
    border-bottom: 1px solid #0086f5; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      border: 0;
      padding: 20px 0;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    .copy {
      display: none;
    }
    &_center {
      padding-left: 64px;
    }
  }
}

@media screen and(max-width:768px) {
  .footer__container {
    &:first-child {
      flex-wrap: wrap;
      justify-content: center;
      padding: 20px 0 15px;
    }
    &:last-child {
      padding: 27px 0;
      justify-content: center;
    }
  }
  .copy {
    display: none;
    padding-bottom: 23px;
    border-bottom: 1px solid #0086f5;
    margin-bottom: 15px;
  }
  .footer__section {
    width: 50%;
    &:first-child {
      width: 100%;
      padding: 0;
    }
    &_center {
      padding: 0 20px 0 0;
    }
    .copy {
      display: block;
      text-align: center;
      padding-top: 10px;
      width: 100%;
    }
  }
  .footer .logo {
    margin: 0 auto;
  }
}
@media screen and(max-width:420px) {
  .footer__section {
    width: 100%;
    align-items: center;
  }
  .footer__section_center {
    padding: 0 0 15px 0;
  }
}