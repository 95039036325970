.home {
  padding-top: 95px;
  color: #fff;
  margin-bottom: 40px;
  background-size: cover;
  .main-button {
    margin-bottom: 92px;
  }
  .arrow-button {
    margin-bottom: 135px;
  }
  &__container {
    padding: 157px 0 0 0;
  }
  &__sub-title {
    display: block;
    text-align: center;
    font-size: 24px;
    font-family: 'OpenSansRegular';
    letter-spacing: 0.05em;
    line-height: 120%;
    padding: 0 0 55px 0;
  }
  &__title {
    display: block;
    text-align: center;
    font-family: 'MontserratExtraBold';
    font-size: 48px;
    letter-spacing: 0.01em;
    margin: 0;
    padding: 0 0 100px 0;
  }
}

@media screen and(min-width:1025px) {
  .home {
    background: url('../img/backgrounds/home-background-1024.jpg') no-repeat center bottom;
    background-size: cover;
  }
}
@media screen and(min-width:577px) and(max-width:1025px) {
  .home {
    background: url('../img/backgrounds/home-background-768.jpg') no-repeat center bottom;
    background-size: cover;
  }
}
@media screen and(max-width:576px) {
  .home {
    background: url('../img/backgrounds/home-background-576.jpg') no-repeat center bottom;
    background-size: cover;
  }
}

@media screen and(max-width:1024px) {
  .home {
    margin: 0;
    &__container {
      padding-top: 80px;
    }
    &__title {
      padding: 0 0 70px 0;
      font-size: 40px;
    }
    .main-button {
      width: 345px;
      height: 90px;
      margin-bottom: 110px;
    }
    .arrow-button {
      display: none;
    }
  }
}
@media screen and(max-width:576px) {
  .home__title {
    font-size: 36px;
  }
  .home__container {
    padding-top: 55px;
  }
  .home__sub-title {
    padding-bottom: 40px;
    br {
      display: none;
    }
  }
  .home .main-button {
    margin-bottom: 100px;
    width: 290px;
    height: 94px;
    &:after {
      background: url('../img/button2.png');
      background-size: 100% 100%;
    }
  }
}
@media screen and(max-width:420px) {
  .home__sub-title {
    font-size: 20px;
    padding-bottom: 25px;
  }
  .home__title {
    font-size: 30px;
    padding-bottom: 35px;
  }
  .home__container {
    padding-top: 20px;
  }
  .home .main-button {
    margin-bottom: 60px;
  }
}