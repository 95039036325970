.header {
  position: absolute;
  width: 100%;
  top: 0;
  color: #fff;
  z-index: 2;
  a {
    color: #fff;
  }
  &__container {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__section {
    display: flex;
    flex-direction: column;
    &_center {
      padding-left: 64px;
    }
  }
}

@media screen and(max-width:768px) {
  .header {
    .container {
      padding: 0;
    }
    &__container {
      padding: 20px 15px;
      position: relative;
    }
    &__section {
      display: none;
    }
  }
}