@import "./partials/normalize";
@import "./partials/fonts";

//fonts
$main-font: 14px "OpenSansSemiBold", sans-serif;
$main-color: #424242;
//size
$main-width: 1010px;
//colors
$main-background-color: #fff;
$color: #ffaf00;

body, html {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100%;
  position: relative;
  //
  &:before {
    position: absolute;
    width: 100%;
    top: 0;
    content: '';
    display: block;
    height: 100%;
    //background: url('../img/desktop.jpg') no-repeat center top;
    background-size: cover;
    z-index: 1;
    opacity: 0.2;
    display: none;
  }
}
.wrapper {
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  z-index: 2;
}
body {
  text-rendering: optimizeSpeed;
  color: $main-color;
  font: $main-font;
  background-color: $main-background-color;
}
.body_active {
  overflow: hidden;
}
.container {
  max-width: $main-width;
  padding: 0 15px;
  margin: 0 auto;
  min-width: 320px;
  position: relative;
}
i {
  color: #5cdcc7;
  font-size: 16px;
}
.up-button {
  display: none;
  cursor: pointer;
  padding: 0;
  width: 68px;
  height: 68px;
  position: fixed;
  bottom: 70px;
  right: 70px;
  z-index: 10;
  outline: none;
  border: 0;
  animation: radial-pulse 1.5s infinite;
  background: transparent;
  border-radius: 100%;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    top: 0;
    background-color: #5cdec2;
    border-radius: 100%;
    transition: 0.3s;
  }
  &:hover {
    &:after {
      opacity: 0.7;
    }
  }
  i {
    color: #fff;
    font-size: 40px;
    position: relative;
    z-index: 3;
  }
}
@keyframes radial-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(92, 210, 209, 0.6);
  }
 
  100% {
    box-shadow: 0 0 0 15px rgba(92, 210, 209, 0);
  }
}
.mail,
.messenger {
  font-size: 16px;
  font-family: 'OpenSansLight';
  white-space: nowrap;
  span {
    padding-left: 14px;
  }
}
.messenger {
  letter-spacing: 0.05em;
}
.main-shadow {
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: 70%;
    top: 10%;
    height: 70%;
    left: 10%;
    box-shadow: 10px 14px 100px 0px rgba(66,212,168,0.31);
    z-index: 1;
    border-radius: 100%;
  }
}
.main-number {
  text-decoration: none;
  font-size: 20px;
  span {
    padding-left: 14px;
    letter-spacing: 0.05em;
  }
  i {
    font-size: 13px;
    transform: rotate(90deg) translateX(-2px);
  }
}
.main-adress {
  font-family: serif;
  font-size: 14px;
  font-family: 'OpenSansLight';
  padding-top: 3px;
  display: flex;
  span {
    padding-left: 14px;
    display: inline-block;
  }
}
.main-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 378px;
  color: #fff;
  height: 94px;
  margin: 0 auto;
  text-decoration: none;
  font-family: 'MontserratExtraBold';
  font-size: 16px;
  text-transform: uppercase;
  transition: opacity 0.3s;
  position: relative;
  span {
    z-index: 3;
    position: relative;
  }
  &:hover {
    &:after {
      opacity: .7;
    }
  }
  &:after {
    background: transparent url('../img/button.png') no-repeat center center;
    background-size: 100% 100%;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transition: opacity 0.3s;
  }
}
.mouse-button {
  display: block;
  width: 30px;
  height: 37px;
  background: url('../img/icons/mouse.png') no-repeat;
  background-size: 100% 100%;
  transition: 0.3s;
  margin: 0 auto;
  transform: rotate(180deg);
  &:hover {
    opacity: .7;
  }
}
.arrow-button {
  width: 26px;
  height: 33px;
  display: block;
  margin: 0 auto;
  background: url('../img/icons/arrow.png');
  background-size: 100%;
  animation-name: arrow;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  transition: 0.3s;
}
.policy-link {
  color: #0086f5;
  text-decoration: none;
  font-family: 'OpenSansLight';
  &:hover {
    text-decoration: underline;
  }
}
.copy {
  color: #3d3d3d;
  font-family: 'OpenSansLight';
}
.logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  &__image {
    display: block;
    height: 55px;
    width: auto;
  }
  &__title {
    padding-left: 11px;
    font-size: 16px;
    margin: 0;
  }
}
@keyframes arrow {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}
//popup
.popup {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 200;
  opacity: 0;
  top: 0;
  left: 0;
  color: #24292e;
  display: none;
  overflow: auto;
  &__overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #020725;
    opacity: 0.74;
  }
  &__container {
    width: 100%;
    min-height: 100%;
    display: flex;
    padding: 40px 15px 15px 15px;
  }
  &__content {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
    max-width: 465px;
    padding: 40px;
    margin: auto;
    background: #fff url('../img/backgrounds/form-background.jpg') no-repeat;
    background-size: 100% 100%;
  }
  &__title {
    font-size: 24px;
    display: block;
    text-align: center;
    font-family: 'OpenSansBold';
    letter-spacing: 0;
    position: relative;
    &:after {
      content: '';
      display: block;
      padding-top: 20px;
      margin: 0 auto;
      background: url('../img/icons/title-icon.png') no-repeat center bottom;
      height: 2px;
      width: 102px;
      background-size: 100% 2px;
    }
  }
  &__close {
    outline: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: 0;
    padding: 0;
    background: transparent;
    position: absolute;
    right: -40px;
    top: -40px;
    transition: 0.3s;
    i {
      font-size: 19px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  &__form {
    display: block;
    width: 100%;
    padding: 25px 0 0 0;
    .main-button {
      margin-top: 30px;
      width: 100%;
      background: transparent;
      border: 0;
      letter-spacing: 0.15em;
      cursor: pointer;
      outline: none;
      i {
        z-index: 3;
        color: #fff;
        padding-left: 10px;
      }
    }
    &-group {
      margin-bottom: 10px;
      position: relative;
      i {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #999999;
        transition: 0.3s;
        z-index: 1;
      }
    }
    &-input {
      border-radius: 0;
      padding: 14px 10px;
      width: 100%;
      border: 2px solid #bababa;
      font-size: 16px !important;
      font-family: 'OpenSansLight';
      color: #000;
      outline: none;
      transition: 0.3s;
      overflow: hidden;
      line-height: normal;
      background-color: transparent;
      -webkit-appearance: none;
      resize: none;
      position: relative;
      z-index: 2;
      &::placeholder {
        color: #999999;
        transition: .3s;
      }
      &:focus {
        border-color: #51cfae;
        &::placeholder {
          color: transparent;
        }
      }
      &:focus ~ i {
        color: #51cfae;
      }
    }
  }
}
.mobile-button {
  transition: .3s;
  width: 50px;
  height: 42px;
  display: none;
  position: relative;
  z-index: 2;
  span {
    display: block;
    position: absolute;
    height: 8px;
    width: 50px;
    left: 0;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    border-radius: 4px;
    background-color: #5ddcca;
    transition: .3s;
    &:before,
    &:after {
      display: block;
      content: "";
      height: 100%;
      border-radius: 4px;
      width: 100%;
      background-color: #5ddcca;
      position: absolute;
      transition: .3s;
      left: 0;
    }
    &:before {
      top: -17px;
    }
    &:after {
      bottom: -17px;
    }
  }
  &_active {
    span {
      background-color: transparent !important;
      &:before {
        transform: rotate(45deg);
        top: 0px;
        border-radius: 0;
      }
      &:after {
        transform: rotate(-45deg);
        bottom: 0px;
        border-radius: 0;
      }
    }
  }
}
.mobile-contacts {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  width: 100%;
  background-color: #042e48;
  padding: 45px 15px 60px 15px;
  transition: transform 0.3s;
  &_active {
    transform: translateY(0%);
  }
  &__title {
    display: block;
    text-align: center;
    font-size: 24px;
    font-family: 'MontserratBold';
    padding-bottom: 45px;
  }
  &__content {
    display: flex;
    justify-content: space-around;
    margin: 0 -10px;
  }
  &__section {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
}

@media screen and(max-width:1024px) {
  
}
@media screen and(max-width:992px) {
  
}
@media screen and(max-width:768px) {
  .mobile-button,
  .mobile-contacts {
    display: block;
  }
}
@media screen and(max-width:576px) {
  .up-button {
    right: 20px;
    bottom: 20px;
  }
  .popup {
    &__close {
      width: 50px;
      height: 50px;
      right: 0;
      top: 0;
    }
  }
  .popup__container {
    padding: 15px;
  }
  .popup__title:after {
    display: none;
  }
  .popup__form {
    padding-top: 35px;
  }
  .popup__form-input {
    padding: 13px 10px;
  }
  .popup__form .main-button {
    width: 290px;
    height: 80px;
    &:after {
      background: url('../img/button.png');
      background-size: 100% 100%;
    }
  }
}
@media screen and(max-width:420px) {
  .popup__content {
    padding: 35px 20px;
  }
  .popup__form {
    padding-top: 20px;
  }
  .popup__form .main-button {
    width: 250px;
    height: 60px;
    margin-top: 20px;
  }
  .mobile-contacts__content {
    flex-direction: column;
    align-items: center;
  }
  .mobile-contacts {
    padding: 35px 15px;
    &__section {
      padding: 10px 0;
      text-align: center;
    }
    &__title {
      padding-bottom: 30px;
    }
  }
}

@import "./partials/header";
@import "./partials/home";
@import "./partials/footer";
@import "./partials/advantages";
@import "./partials/info";