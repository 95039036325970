.info {
  color: #fff;
  .arrow-button {
    background: url('../img/icons/arrow_blue.png');
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -13px;
  }
  &__container {
    padding: 59px 0 120px 0;
    position: relative;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    padding: 59px 0 50px;
  }
  &__block {
    width: 46.5%;
    &-container {
      padding: 49px 54px 70px 54px;
      border-radius: 14px;
      background-color: rgba(20, 23, 39, 0.65);
      height: 100%;
    }
    &-title {
      font-family: 'MontserratMedium';
      font-size: 30px;
      color: #5cdebe;
      display: block;
      padding-bottom: 40px;
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &-item {
      font-family: 'OpenSansRegular';
      font-size: 16px;
      padding-left: 35px;
      position: relative;
      &:not(:last-child) {
        padding-bottom: 20px;
      }
      i {
        position: absolute;
        left: 0;
        top: 3px;
      }
      &_style2 {
        &:not(:last-child) {
          padding-bottom: 26px;
        }
      }
    }
  }
  &__post-text {
    border-radius: 14px;
    background-color: rgba(20, 23, 39, 0.65);
    display: block;
    text-align: center;
    padding: 19px;
    font-size: 24px;
    font-family: 'MontserratMedium';
  }
}

@media screen and(min-width:577px) {
  .info {
    background: url('../img/backgrounds/bottom-background-1024.jpg') no-repeat center top;
    background-size: cover;
  }
}
@media screen and(max-width:1024px) {
  .info {
    &__content {
      margin: 0 -15px;
      padding-bottom: 30px;
    }
    &__block {
      width: 50%;
      padding: 0 15px;
    }
    &__block-title {
      font-size: 24px;
    }
    &__post-text {
      font-size: 20px;
    }
    &__block-container {
      padding: 35px 35px 50px 35px;
    }
    &__container {
      padding: 59px 0 85px 0;
    }
  }
}
@media screen and(max-width:700px) {
  .info__content {
    display: block;
    padding-bottom: 26px;
  }
  .info__block {
    width: 100%;
    &:first-child {
      padding-bottom: 26px;
    }
  }
  .info__container {
    padding-bottom: 70px;
  }
}
@media screen and(max-width:576px) {
  .info {
    background: url('../img/backgrounds/bottom-background-576.jpg') no-repeat center top;
    background-size: cover;
  }
}
@media screen and(max-width:420px) {
  .info {
    &__block-title {
      font-size: 20px;
      padding-bottom: 20px;
    }
    &__content {
      padding: 20px 0 26px 0;
    }
    &__post-text {
      font-size: 18px;
    }
    &__block-container {
      padding: 30px 20px 40px 20px;
    }
    .info__block-item:not(:last-child) {
      padding-bottom: 10px;
    }
  }
}