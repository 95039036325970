@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;

		@if $asset-pipeline == true {
			src: font-url('#{$file-path}.woff') format('WOFF');
		} @else {
			src: url('#{$file-path}.woff') format('WOFF');
		}
	}
}