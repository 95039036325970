.advantages {
  color: #000;
  padding: 68px 0 95px 0;
  position: relative;
  .container {
    z-index: 2;
  }
  .main-button {
    width: 275px;
    height: 80px;
    font-size: 14px;
    &:after {
      background: url('../img/button2.png');
      background-size: 100% 100%;
    }
  }
  &__title {
    font-size: 42px;
    font-family: 'MontserratExtraBold';
    display: block;
    text-align: center;
    margin: 0;
    padding-bottom: 40px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    padding: 0 50px 68px 50px;
  }
  &__block {
    &-image {
      display: block;
      padding-bottom: 2px;
      width: 220px;
      height: auto;
    }
    &-text {
      display: block;
      text-align: center;
      letter-spacing: 0.07em;
      line-height: 1.5em;
      font-size: 16px;
    }
  }
}

@media screen and(min-width:1025px) {
  .advantages {
    &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:before {
    background: url('../img/backgrounds/left-background-min.png') no-repeat center left;
  }
  &:after {
    background: url('../img/backgrounds/right-background-min.png') no-repeat center right;
  }
  }
}
@media screen and(max-width:1024px) {
  .advantages {
    padding: 45px 0 75px 0;
    &__content {
      padding: 0 0 60px 0;
    }
    .main-button {
      width: 318px;
      &:after {
        background: url('../img/button.png');
        background-size: 100% 100%;
      }
    }
  }
}
@media screen and(max-width:768px) {
  .advantages {
    &__block {
      width: 33.33%;
      padding: 0 15px;
      &-image {
        width: 100%;
        height: auto;
      }
    }
    &__content {
      margin: 0 -15px;
    }
  }
}
@media screen and(max-width:576px) {
  .advantages {
    padding: 40px 0 60px 0;
    .main-button {
      width: 290px;
      height: 80px;
      &:after {
        background: url('../img/button.png');
        background-size: 100% 100%;
      }
    }
    &__block {
      width: 50%;
      &-image {
        width: 100%;
        height: auto;
      }
    }
    &__content {
      flex-wrap: wrap;
      justify-content: center;
    }
    &__title {
      font-size: 36px;
    }
  }
}
@media screen and(max-width:420px) {
  .advantages__block-text {
    font-size: 14px;
    padding: 0 10px;
  }
  .advantages__title {
    font-size: 30px;
    padding-bottom: 30px;
  }
  .advantages__block {
    padding: 0;
  }
  .advantages__content {
    margin: 0;
  }
}